import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./reducers/rootReducer"
// No need to import `thunk` separately, as it's included with Redux Toolkit

const isBrowser = typeof window !== "undefined"

// Function to save state to window object
const saveToWindow = (state = {}) => {
  if (isBrowser) {
    window.lifeAndStyleState = JSON.stringify(state) // Consider serializing state
  }
}

// Function to load state from window object
const loadFromWindow = () => {
  if (isBrowser && window.lifeAndStyleState) {
    return JSON.parse(window.lifeAndStyleState) // Parse the serialized state
  }
  return undefined // Return undefined if no state in window
}

// Configure and create the Redux store
const reduxStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadFromWindow(), // Use the loaded state as initial state
    // Redux Toolkit includes `redux-thunk` by default, no need to add it explicitly
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(yourAdditionalMiddleware),
  })

  // Subscribe to store changes to save state to window
  store.subscribe(() => {
    saveToWindow(store.getState())
  })

  return store
}

export { reduxStore, saveToWindow }
