/**
 * Redux Types
 */

export const MENU = {
	SET_OPEN: "MENU_SET_OPEN",
	SET_CLOSED: "MENU_SET_CLOSED",
};

export const MODULE_TWO = {
	SHOW_BLOGS: "MODULE_TWO_SHOW_BLOGS",
	HIDE_BLOGS: "MODULE_TWO_HIDE_BLOGS",
};

export const SET_CATEGORY_FILTER = "SET_CATEGORY_FILTER";

export const EXPAND_ROW_STATE = "EXPAND_ROW_STATE";
