/**
 * Reducer - Module Two, show / hide blogs state
 */

import { MODULE_TWO } from "../actions/types";

const DEFAULT_MODULE_TWO_SHOW_BLOGS = {
	showBlogs: false,
};

const moduleTwoReducer = (state = DEFAULT_MODULE_TWO_SHOW_BLOGS, action) => {
	switch (action.type) {
		case MODULE_TWO.SHOW_BLOGS:
			return {
				...state,
				showBlogs: action.showBlogs,
			};
		case MODULE_TWO.HIDE_BLOGS:
			return {
				...state,
				showBlogs: action.showBlogs,
			};
		default:
			return state;
	}
};

export default moduleTwoReducer;
