/**
 * Redux Root Reducers
 */

import { combineReducers } from "redux";
import menuReducer from "./menuReducer";
import moduleTwoReducer from "./moduleTwoReducer";
import categoryFilterReducer from "./categoryFilterReducer";
import expandRowReducer from "./expandRowReducer";

export default combineReducers({
	menu: menuReducer,
	moduleTwo: moduleTwoReducer,
	categoryFilter: categoryFilterReducer,
	expandRow: expandRowReducer
});
