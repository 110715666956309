import { EXPAND_ROW_STATE } from "../actions/types";

const expandRowReducer = (state = [], action) => {
    switch (action.type) {
		case EXPAND_ROW_STATE:
			const expanderIndex = state.findIndex( expander => expander.id === action.payload.id );
			if( expanderIndex >= 0 ) {
				state[expanderIndex] = action.payload;
			} else {
				state.push(action.payload);
			}
			return state;
		default:
			return state;
	}
}

export default expandRowReducer;