/**
 * Reducer - Category filter
 */


import { SET_CATEGORY_FILTER } from "../actions/types";

const DEFAULT_CATEGORY = {
	category: {
		name: null,
		id: null,
		subcategorySlug: null,
	},
};


const categoryFilterReducer = (state = DEFAULT_CATEGORY, action) => {
	switch (action.type) {
		case SET_CATEGORY_FILTER:
			return {
				...state,
				category: action.category,
			};
		default:
			return state;
	}
};

export default categoryFilterReducer;
