/**
 * Reducer - Module Two, mobile menu open / closed state
 */

import { MENU } from "../actions/types";

const DEFAULT_MENU = {
	menuOpen: false,
};

const menuReducer = (state = DEFAULT_MENU, action) => {
	switch (action.type) {
		case MENU.SET_OPEN:
			return {
				...state,
				menuOpen: true,
			};
		case MENU.SET_CLOSED:
			return {
				...state,
				menuOpen: false,
			};
		default:
			return state;
	}
};

export default menuReducer;
